import debounce from 'lodash.debounce';
import { mapState, mapGetters } from 'vuex';

export default {
    data: () => ({
        debouncedGetEmployeesWithPhone: null,
        isEmployeesWithPhoneLoading: false,
        searchEmployeesWithPhone: null,
        employeesWithPhonePaginationPage: 1
    }),
    computed: {
        ...mapState('employees', [
            'totalEmployeesWithPhoneAmount'
        ]),
        ...mapGetters('employees', [
            'employeesWithPhone'
        ])
    },
    watch: {
        searchEmployeesWithEmail: {
            handler (val) {
                if (!val || this.isEmployeesWithPhoneLoading) {
                    return;
                }

                this.debouncedGetEmployeesWithPhone(val);
            }
        }
    },
    methods: {
        getEmployeesWithPhone (additionalParams = null) {
            this.isEmployeesWithPhoneLoading = true;

            let params = {};

            if (typeof additionalParams === 'string') {
                params['filter[full_name][match]'] = additionalParams;
            } else if (typeof additionalParams === 'object') {
                params = { ...additionalParams };
            }

            return this.$store.dispatch('employees/getEmployeesWithPhone', params)
                .then(() => {
                    this.employeesWithPhonePaginationPage = 1;
                })
                .finally(() => {
                    this.isEmployeesWithPhoneLoading = false;
                });
        },
        getEmployeesWithPhoneLazyLoading (additionalParams = null) {
            if (this.totalEmployeesWithPhoneAmount > this.employeesWithPhone.length) {
                this.isEmployeesWithPhoneLoading = true;

                this.employeesWithPhonePaginationPage += 1;

                let params = {};

                if (typeof additionalParams === 'object') {
                    params = { ...additionalParams };
                }

                params['page[number]'] = this.employeesWithPhonePaginationPage;

                const _name = this.searchEmployeesWithPhone;

                if (_name) {
                    params['filter[full_name][match]'] = _name;
                }

                return this.$store.dispatch('employees/getEmployeesWithPhoneLazyLoading', params)
                    .finally(() => {
                        this.isEmployeesWithPhoneLoading = false;
                    });
            }
        }
    },
    created () {
        this.debouncedGetEmployeesWithPhone = debounce(this.getEmployeesWithPhone, 1000);
    }
};
