import debounce from 'lodash.debounce';
import { mapState, mapGetters } from 'vuex';

export default {
    data: () => ({
        debouncedGetSubContractorsTypes: null,
        isSubContractorsTypesLoading: false,
        searchSubContractorsTypes: null,
        subContractorsTypesPaginationPage: 1
    }),
    computed: {
        ...mapState('subContractorsTypes', [
            'totalSubContractorsTypesAmount'
        ]),
        ...mapGetters('subContractorsTypes', [
            'subContractorsTypes'
        ])
    },
    watch: {
        searchSubContractorsTypes: {
            handler (val) {
                if (!val || this.isSubContractorsTypesLoading) {
                    return;
                }

                this.debouncedGetSubContractorsTypes(val);
            }
        }
    },
    methods: {
        getSubContractorsTypes (additionalParams = null) {
            this.isSubContractorsTypesLoading = true;

            let params = {};

            if (typeof additionalParams === 'string') {
                params.search = additionalParams;
            } else if (typeof additionalParams === 'object') {
                params = { ...additionalParams };
            }

            return this.$store.dispatch('subContractorsTypes/getSubContractorsTypes', params)
                .then(() => {
                    this.subContractorsTypesPaginationPage = 1;
                })
                .finally(() => {
                    this.isSubContractorsTypesLoading = false;
                });
        },
        getSubContractorsTypesLazyLoading (additionalParams = null) {
            if (this.totalSubContractorsTypesAmount > this.subContractorsTypes.length) {
                this.isSubContractorsTypesLoading = true;

                this.subContractorsTypesPaginationPage += 1;

                let params = {};

                if (typeof additionalParams === 'object') {
                    params = { ...additionalParams };
                }

                params['page[number]'] = this.subContractorsTypesPaginationPage;

                const _name = this.searchSubContractorsTypes;

                if (_name) {
                    params.search = _name;
                }

                return this.$store.dispatch('subContractorsTypes/getSubContractorsTypesLazyLoading', params)
                    .finally(() => {
                        this.isSubContractorsTypesLoading = false;
                    });
            }
        }
    },
    created () {
        this.debouncedGetSubContractorsTypes = debounce(this.getSubContractorsTypes, 1000);
    }
};
