<template>
    <v-sheet color="white"
             class="px-0 py-0"
             elevation="0">
        <ValidationObserver ref="obs">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <TextField :label="$t('labels.name')"
                                   v-model="name"
                                   rules="required"></TextField>

                        <Autocomplete :label="$t('labels.type')"
                                    :placeholder="$t('labels.type')"
                                    name="type_id"
                                    v-model="type_id"
                                    clearable
                                    :items="subContractorsTypes"
                                    :loading="isSubContractorsTypesLoading"
                                    :search-input.sync="searchSubContractorsTypes"
                                    @click:clear="getSubContractorsTypes"
                                    @load-more-items="getSubContractorsTypesLazyLoading"
                                    >
                        </Autocomplete>

                        <Autocomplete :label="$t('labels.persons_to_sms_notify')"
                                    :placeholder="$t('labels.persons_to_sms_notify')"
                                    name="sms_receivers"
                                    v-model="sms_receivers"
                                    clearable
                                    :multiple="true"
                                    :items="employeesWithPhone"
                                    :loading="isEmployeesWithPhoneLoading"
                                    :search-input.sync="searchEmployeesWithPhone"
                                    @click:clear="getEmployeesWithPhone"
                                    @load-more-items="getEmployeesWithPhoneLazyLoading"
                                    >
                        </Autocomplete>

                        <Autocomplete :label="$t('labels.persons_to_email_notify')"
                                    :placeholder="$t('labels.persons_to_email_notify')"
                                    name="email_receivers"
                                    v-model="email_receivers"
                                    clearable
                                    :multiple="true"
                                    :items="employeesWithEmail"
                                    :loading="isEmployeesWithEmailLoading"
                                    :search-input.sync="searchEmployeesWithEmail"
                                    @click:clear="getEmployeesWithEmail"
                                    @load-more-items="getEmployeesWithEmailLazyLoading"
                                    >
                        </Autocomplete>
                    </v-col>
                </v-row>

            </v-container>
        </ValidationObserver>
    </v-sheet>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ValidationObserver } from 'vee-validate';
import TextField from '@/components/widgets/forms/TextField';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import debouncedSubContactorsTypes from '@/mixins/debounced/debouncedSubContactorsTypes';
import debouncedEmployeesWithEmail from '@/mixins/debounced/debouncedEmployeesWithEmail';
import debouncedEmployeesWithPhone from '@/mixins/debounced/debouncedEmployeesWithPhone';

export default {
    name: 'SubContractorsForm',
    components: { ValidationObserver, TextField, Autocomplete },
    mixins: [
        debouncedSubContactorsTypes,
        debouncedEmployeesWithEmail,
        debouncedEmployeesWithPhone
    ],
    props: {
        isEditable: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        ...mapFields('manageSubContractors', [
            'subContractorsData.name',
            'subContractorsData.type_id',
            'subContractorsData.sms_receivers',
            'subContractorsData.email_receivers'
        ]),
        ...mapState('manageSubContractors', [
            'subContractorsFormVisibility'
        ])
    },
    methods: {
        resetValidation () {
            return this.$refs.obs.reset();
        },
        validate () {
            return this.$refs.obs.validate();
        }
    },
    watch: {
        subContractorsFormVisibility: {
            handler (val) {
                if (val) {
                    this.getSubContractorsTypes();
                    this.getEmployeesWithEmail();
                    this.getEmployeesWithPhone();
                }
            },
            immediate: true
        }
    }
};
</script>
