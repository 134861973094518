import debounce from 'lodash.debounce';
import { mapState, mapGetters } from 'vuex';

export default {
    data: () => ({
        debouncedGetEmployeesWithEmail: null,
        isEmployeesWithEmailLoading: false,
        searchEmployeesWithEmail: null,
        employeesWithEmailPaginationPage: 1
    }),
    computed: {
        ...mapState('employees', [
            'totalEmployeesWithEmailAmount'
        ]),
        ...mapGetters('employees', [
            'employeesWithEmail'
        ])
    },
    watch: {
        searchEmployeesWithEmail: {
            handler (val) {
                if (!val || this.isEmployeesWithEmailLoading) {
                    return;
                }

                this.debouncedGetEmployeesWithEmail(val);
            }
        }
    },
    methods: {
        getEmployeesWithEmail (additionalParams = null) {
            this.isEmployeesWithEmailLoading = true;

            let params = {};

            if (typeof additionalParams === 'string') {
                params['filter[full_name][match]'] = additionalParams;
            } else if (typeof additionalParams === 'object') {
                params = { ...additionalParams };
            }

            return this.$store.dispatch('employees/getEmployeesWithEmail', params)
                .then(() => {
                    this.employeesWithEmailPaginationPage = 1;
                })
                .finally(() => {
                    this.isEmployeesWithEmailLoading = false;
                });
        },
        getEmployeesWithEmailLazyLoading (additionalParams = null) {
            if (this.totalEmployeesWithEmailAmount > this.employeesWithEmail.length) {
                this.isEmployeesWithEmailLoading = true;

                this.employeesWithEmailPaginationPage += 1;

                let params = {};

                if (typeof additionalParams === 'object') {
                    params = { ...additionalParams };
                }

                params['page[number]'] = this.employeesWithEmailPaginationPage;
                params['filter[is_item]'] = false;

                const _name = this.searchEmployeesWithEmail;

                if (_name) {
                    params['filter[full_name][match]'] = _name;
                }

                return this.$store.dispatch('employees/getEmployeesWithEmailLazyLoading', params)
                    .finally(() => {
                        this.isEmployeesWithEmailLoading = false;
                    });
            }
        }
    },
    created () {
        this.debouncedGetEmployeesWithEmail = debounce(this.getEmployeesWithEmail, 1000);
    }
};
